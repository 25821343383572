@font-face {
  font-family: "Prompt";
  src: url("/public/fonts/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Prompt", "serif";
}

.app {
  display: flex;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}